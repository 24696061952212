import React from 'react';
import module_style from './Style.module.css';
import style from '../../Style.module.css';
import { is_empty } from '../../utils/validations';
import {
    ResponsiveContainer,
    Bar, Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, ComposedChart
} from 'recharts';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import constants from '../../constants/constants';
import EmptyView from '../pages/Dashboard/EmptyView';
import { is_desktop, roundOff } from '../../utils/Utils';


const LineGraph = (props) => {
    const { data, dataKey, title, description, filter, cta2, cta1, tabs, zoom, processing, empty } = props;

    const [show_modal, set_show_modal] = React.useState(false);

    const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
        return <text className={`${module_style.axis_tick}`} x={x} y={y} fill="#666" textAnchor="end" >{`${payload.value > 10000 ? `${parseInt(payload.value / 1000)}K` : payload.value}`}</text>;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (!is_empty(payload) && !is_empty(payload[0]) && !is_empty(payload[0].payload) && !is_empty(payload[0].payload.tooltip))
            return (
                <div className={`${module_style.chart_tooltip}`}>{payload[0].payload.tooltip}</div>
            )

        return null
    }

    const renderGraph = (check) => (
        <>{processing || (check && is_empty(zoom.data)) ?
            <div className={`${style.loader_conatiner} ${style.mobile_input}`}>
                <div className={`${style.dashboard_card_title}`}>{title}</div>
                <div className={`${style.loader_wrapper}`}>
                    <div class="loader-wrap">
                        <svg class="circle-loader progress" width="40" height="40" version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle style={{ width: '100%', height: '100%', stroke: '#6c5dd3' }} cx="20" cy="20" r="15"></circle>
                        </svg>
                    </div>
                    <span>Your data will be ready in a moment</span>
                </div>
            </div>
            :
            <div>
                <div className={`${module_style.container} row no-gutters`}>
                    {title && <div style={{ width: '27%' }} className={`${module_style.title} mr-1`}>{title}</div>}
                    <div style={{flexGrow: 1, width: '70%'}} className={`row no-gutters justify-content-end`}>
                        {!is_empty(zoom) && !check && is_desktop && <ZoomOutMapIcon onClick={() => {
                            set_show_modal(true);
                            zoom.handle();
                        }} className={`${module_style.zoom_icon} mr-1`} />}
                        {!is_empty(filter) && is_desktop && <select onChange={(e) => {
                            let value = parseInt(e.target.value);
                            filter.handle(value);
                        }} className={` ${style.dropdown} mr-1`} name="LineGraphfilter" id="LineGraphfilter">
                            {filter.list.map((item) => <option selected={filter.selected_value === item.value} value={item.value}>{item.title}</option>)}
                        </select>}
                        {!is_empty(tabs) && tabs.list.map((item, index) => <div onClick={() => {
                            if (tabs.selected === item.value) return;
                            tabs.handle(item.value);
                        }} style={index === 0 ? { marginLeft: is_desktop ? '10px' : '0px' } : {}} className={`pointer ${style.chart_action} ${tabs.selected === item.value ? style.chart_action_selected : ''}`}>{item.title}</div>)}
                        {!is_empty(filter) && !is_desktop && <select onChange={(e) => {
                            let value = parseInt(e.target.value);
                            filter.handle(value);
                        }} className={` ${style.dropdown} mr-1`} name="LineGraphfilter" id="LineGraphfilter" style={{ position: 'absolute', right: '0' }}>
                            {filter.list.map((item) => <option selected={filter.selected_value === item.value} value={item.value}>{item.title}</option>)}
                        </select>}
                        {description && <div>{description}</div>}
                    </div>
                </div>

                {(is_empty(data) && is_empty(zoom?.data)) || empty?.force ?

                    <EmptyView handleCta={() => {
                        empty.handle();
                    }} preview_image={empty.preview_image} width={empty.width} height={empty.height} title={empty.title} cta_title={empty.cta_title} />
                    :
                    <ResponsiveContainer width={check ? 80 * zoom.data.length : '100%'} height={check && window.screen.width > constants.mobile_width ? 500 : 300}>
                        <ComposedChart data={check ? zoom.data : data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="x" />
                            <YAxis tick={renderCustomBarLabel} hide={window.screen.width < 780} type={'number'} allowDecimals={false} domain={[0, dataMax => (roundOff(dataMax * 1.3, 0))]} />
                            <Tooltip wrapperStyle={{ maxWidth: '80%' }} content={<CustomTooltip />} />
                            <Bar dataKey={dataKey} barSize={20} fill="#413ea0" />
                            <Line type="monotone" dataKey={dataKey} stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                }

                <div className={`row no-gutters ${module_style.cta_container}`}>
                    {!is_empty(cta1) && <div onClick={() => cta1.handle()} className={`${style.save_cta}`}>{cta1.title}</div>}
                    {!is_empty(cta2) && <div onClick={() => cta2.handle()} className={`${style.save_cta}`}>{cta2.title}</div>}
                </div>
            </div>
        }</>

    )

    return (
        <>
            {renderGraph()}

            {show_modal && <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
                onClose={() => {
                    zoom.handleClose();
                    set_show_modal(false);
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div style={{ maxWidth: '90%', minWidth: '35%' }} className={`custom_modal fit_content`}>{renderGraph(true)}</div>
            </Modal>}
        </>
    );

}

export default LineGraph;
