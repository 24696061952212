import React from "react";
import { useNotify } from "react-admin";
import FacebookIcon from "../../../assets/vectors/social/facebook.svg";
import TwitterIcon from "../../../assets/vectors/social/twitter.svg";
import WhatsAppIcon from "../../../assets/vectors/social/whatsapp.svg";
import InstagramIcon from "../../../assets/vectors/social/instagram.svg";
import EmailRounded from "../../../assets/vectors/social/emailRounded.svg";
import DownloadButton from "../../../assets/vectors/DownloadButton.svg";
import style from "./Style.module.css";
import { toBlob, toJpeg } from "html-to-image";
import { Button, Modal } from "@my-scoot/component-library-legacy";
import { is_empty } from "utils/validations";
import { Creative } from "./Creative";
import { is_desktop } from "utils/Utils";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { appendUrlParams } from "utils/urlUtils";
import constants from "constants/constants";

function LimitedOfferShare(props) {
  const notify = useNotify();
  const { setShowModal, firstOffer, listing } = props;

  const [share_link, set_share_link] = React.useState(listing?.share_url);
  const shareText = `Limited time offer on ${listing?.listing_name}. Grab your spot: ${share_link}`;

  const downloadBanner = () => {
    toJpeg(document.getElementById("creative_conatiner"), {
      cacheBust: true,
    }).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "id" + ".jpeg";
      link.href = dataUrl;
      link.click();
    });
  };

  const handleBroadcast = async () => {
    try {
      window.location.href = `${window.location.origin}/#/ComposeEmail`;
    } catch (err) {
      notify(err.message);
    }
  };

  React.useEffect(() => {
    set_share_link(listing.share_url);
  }, [listing.share_url]);

  const onShareClick = async () => {
    if (navigator?.share) {
      notify("Preparing your creative.. please wait");
      toBlob(document.getElementById("creative_conatiner"), {
        cacheBust: true,
      }).then((blob) => {
        navigator
          .share({
            title: `${listing?.listing_name}`,
            text: shareText,
            files: [
              new File([blob], "sharingBanner" + ".png", {
                type: blob.type,
                lastModified: new Date().getTime(),
              }),
            ],
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      notify("System does not support sharing files");
    }
  };

  const LimitedOfferShareModal = () => {
    return (
      <div className={`fit_content ${style.share_modal}`}>
        <div
          className={style.share_modal_close}
          title="close"
          onClick={() => {
            window.location.reload();
          }}
        >
          &times;
        </div>
        <p className={style.limitedOfferShareHeader}>
          Congrats! 🎉 Your {firstOffer ? "1st" : ""} Limited-Time Offer is now
          live! 😃
        </p>
        <div className={style.limitedOfferShareBox}>
          <div className={`d-flex justify-content-center`}>
            {!is_empty(listing?.listing_cover) && (
              <Creative listing={listing} />
            )}
          </div>
          <div className={style.modal_event_link}>
            <a target="_blank" rel="noopener noreferrer" href={share_link}>
              {share_link}
            </a>
          </div>

          <div className={style.copy_button}>
            <Button
              color="primary"
              size="medium"
              onClick={() => {
                if (navigator && navigator.clipboard) {
                  navigator.clipboard.writeText(share_link);
                  notify("Link Copied!");
                }
              }}
            >
              Copy Link
            </Button>
          </div>

          <div>
            <div className={`${style.subTitle} p-3 border-bottom`}>
              Share this as post on Social Media to get bookings
            </div>
            <div className="pt-2 d-flex justify-content-around">
              <img
                className={`${style.share_social_icon} pointer`}
                src={WhatsAppIcon}
                onClick={() => {
                  is_desktop
                    ? window.open(
                        `https://api.whatsapp.com/send?&text=${shareText}`
                      )
                    : onShareClick();
                }}
              />
              <img
                className={`${style.share_social_icon} pointer`}
                src={InstagramIcon}
                onClick={() => {
                  if (is_desktop) {
                    notify("Creative downloaded! You can share now.");
                    downloadBanner();
                  }
                  onShareClick();
                }}
              />
              <img
                className={`${style.share_social_icon} pointer`}
                src={FacebookIcon}
                onClick={() => {
                  is_desktop
                    ? window.open(
                        appendUrlParams(constants.facebook_share_url, {
                          link: share_link,
                          quote: shareText,
                        })
                      )
                    : onShareClick();
                }}
              />
              <img
                className={`${style.share_social_icon} pointer`}
                src={TwitterIcon}
                onClick={() => {
                  is_desktop
                    ? window.open(
                        `https://twitter.com/intent/tweet?text=${shareText}`
                      )
                    : onShareClick();
                }}
              />
              <img
                className={`${style.share_social_icon} pointer`}
                src={EmailRounded}
                onClick={() => {
                  is_desktop ? handleBroadcast() : onShareClick();
                }}
              />
            </div>
          </div>
        </div>
        {is_desktop ? (
          <div className={style.limitedOfferShareBox}>
            <div className={`${style.subTitle} pb-2 border-bottom`}>
              Download this Banner
            </div>

            <div className="pt-2 d-flex justify-content-around">
              <img
                className={`${style.share_social_icon} pointer`}
                src={DownloadButton}
                onClick={downloadBanner}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={setShowModal}
        fullScreen={!is_desktop}
        onClose={() => {
          window.location.reload();
        }}
        customFooter={<></>}
        customHeader={<></>}
      >
        <LimitedOfferShareModal />
      </Modal>
    </>
  );
}

export default withComponentLibraryTheme(LimitedOfferShare);
