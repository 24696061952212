import module_style from "./Style.module.css";
import React from "react";
import { is_empty } from "utils/validations";
import FlashSale from "assets/vectors/LimitedTimeOfferSharing/FlashSale.svg";
import LeftSpeaker from "assets/vectors/LimitedTimeOfferSharing/LeftSpeaker.svg";
import RightSpeaker from "assets/vectors/LimitedTimeOfferSharing/RightSpeaker.svg";
import LeftCircle from "assets/vectors/LimitedTimeOfferSharing/LeftCircle.svg";
import RightCircle from "assets/vectors/LimitedTimeOfferSharing/RightCircle.svg";
import Calendar from "assets/vectors/LimitedTimeOfferSharing/Calendar.svg";
import { getAuthData } from "utils/AuthUtil";
import { getUserTimezone } from "utils/AuthUtil";
import constants from "constants/constants";
import moment from "moment";

const themes = [
  {
    slug: "blue",
    color:
      "radial-gradient(117.67% 440.77% at 82.02% 104.53%, #D9E8FE 0%, rgba(32, 119, 248, 0.1) 100%)#FFFFFF",
  },
  {
    slug: "green",
    color: "linear-gradient(0deg, #15CCD0, #15CCD0), #FFFFFF",
  },
  {
    slug: "orange",
    color:
      "linear-gradient(302.43deg, #E100FF -73.46%, #FFDC48 91.93%), #FFFFFF",
  },
];

export const Creative = ({ listing, website_share }) => {
  const [theme] = React.useState(
    themes[Math.floor(Math.random() * themes.length)]
  );
  const discountPrice =
    listing?.region === 0
      ? constants.CURRENCY_SYMBOL_MAP.INR
      : constants.CURRENCY_SYMBOL_MAP.USD;

  return (
    <div
      style={
        website_share
          ? { border: "1px solid transparent", padding: 0, minHeight: "unset" }
          : {}
      }
      className={`${module_style.creative_conatiner} dm_font`}
      id="creative_conatiner"
    >
      {is_empty(listing?.listing_cover) ? (
        <></>
      ) : (
        <div
          className={`${module_style.listing_data_container}`}
          style={{ background: `${theme.color}` }}
        >
          <div className={module_style.OfferTitlePhoto}>
            <img
              id="creative_cover_image"
              src={`${listing?.listing_cover}?test=123`}
              className={`${module_style.cover_image}`}
              alt="cover_image"
            />
            <div className={module_style.CreativeTitle}>
              <div
                className={`${module_style.listing_title} line_max_1 ${module_style.glassMorph}`}
              >
                <p className={`${module_style.listing_title}`}>
                  {listing?.listing_name}
                </p>
                <p className={`pb-1 ${module_style.creator_name}`}>
                  By {getAuthData().display_name}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={module_style.SaleImage}>
              <img
                className={module_style.DecorativeShapes}
                src={LeftSpeaker}
                alt="cover_image"
              />
              <img
                className={module_style.flashSale}
                src={FlashSale}
                alt="cover_image"
              />
              <img
                className={module_style.DecorativeShapes}
                src={RightSpeaker}
                alt="cover_image"
              />
            </div>

            <div className={module_style.CirclesWithOrnament}>
              <img
                className={module_style.DecorativeShapes}
                src={LeftCircle}
                alt="Circle"
              />

              <div
                className={`${module_style.OfferValidity} line_max_1 ${module_style.glassMorph}`}
              >
                <span>
                  <img src={Calendar} className={module_style.calendarIcon} />
                </span>
                <span>
                  <p className={module_style.validityHeader}>
                    Limited-Time Offer Valid From
                  </p>
                  <p className={module_style.validityContent}>
                    {moment(listing?.start_datetime).format("DD MMM YYYY")} To
                  </p>
                  <p className={module_style.validityContent}>
                    {moment(listing?.end_datetime)
                      .tz(getUserTimezone())
                      .format("DD MMM YYYY")}
                  </p>
                </span>
              </div>

              <img
                className={module_style.DecorativeShapes}
                src={RightCircle}
                alt="Circle"
              />
              <div className={module_style.leftPrice}>
                <p className={`${module_style.ActualPrice}`}>
                  {listing?.listing_price}
                  {discountPrice}
                </p>
                <p className={`${module_style.DiscountPrice}`}>
                  {listing?.price}
                  {discountPrice}
                </p>
              </div>
              <div className={module_style.rightPrice}>
                <p className={`${module_style.ActualPrice}`}>
                  {listing?.listing_price}
                  {discountPrice}
                </p>
                <p className={`${module_style.DiscountPrice}`}>
                  {listing?.price}
                  {discountPrice}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
