import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './Style.module.css';

const PreviewModal = (props) => {

    const { preview_image } = props;

    return (
        <div className={`alert_modal preview_modal`}>
            <span className={`${styles.preview_cta}`} style={{ margin: 0 }} onClick={() => {
                props.close();
            }} >✖</span>
            <img src={ window.screen.width > 780 ? require(`../../../assets/images/${preview_image}`) : require(`../../../assets/images/mobile_preview/${preview_image}`)} alt="preview_image" className={styles.preview_image} />
        </div>
    );

}

export default PreviewModal;
