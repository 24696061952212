import React, { Component } from "react";
import style from "./Style.module.css";
import { api } from "data";
import classnames from "classnames";
import { FORM_DATA } from "./transactionHeader.constant";

class TransactionHeader extends Component {
  render() {
    return (
      <div className={"dm_font"}>
        <div className={`${style.flex_row} `}>
          <span
            className={classnames(style.bold_black_large_text, style.flex4)}
          >
            Manage Transactions
          </span>
        </div>

        <div className={classnames(style.flex_row, style.mTop)}>
          <span
            //  TODO: mayank handle the constant
            className={`${style.tab_title} ${
              this.props.active === 0 && style.selected_tab_title
            }`}
            onClick={() => {
              window.location.href = `${window.location.origin}/#/${api.transaction_list}`;
              window.location.reload();
            }}
          >
            {FORM_DATA.transaction}
          </span>
          <span
            //  TODO: mayank handle the constant
            className={`${style.tab_title} ${
              this.props.active === 3 && style.selected_tab_title
            }`}
            onClick={() => {
              window.location.href = `${window.location.origin}/#/payments/exly/subscription/list`;
              window.location.reload();
            }}
          >
            {FORM_DATA.subscription}
          </span>
        </div>

        <div className={style.line}></div>
      </div>
    );
  }
}

export default TransactionHeader;
