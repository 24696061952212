import { Datagrid, List } from "react-admin";
import React from "react";
import constants from "../../../constants/constants";
import Style from "../mobileCardView/mobileCard.module.css";
import { orgPermissions } from "../../../utils/OrgPermissions";

export const CustomGrid = (props) => {
  const { ListPagination, PostFilter, bulkActionButtons, resource, basePath, empty, filterDefaultValues, datagridClassName } = props;
  const isDesktop = window.screen.width > constants.mobile_width;

  return (
    <div id="CustomGrid">
      {isDesktop ? (
        <List
          {...props.props}
          filterDefaultValues={filterDefaultValues}
          empty={empty}
          resource={resource}
          basePath={basePath}
          exporter={orgPermissions.canExport() ? props.exporter : false}
          perPage={50}
          bulkActionButtons={bulkActionButtons ? bulkActionButtons : false}
          pagination={ListPagination ? <ListPagination /> : <></>}
          filters={PostFilter ? <PostFilter /> : <></>}
        >
          <Datagrid className={datagridClassName}>{props.children}</Datagrid>
        </List>
      ) : (
        <List
          {...props.props}
          filterDefaultValues={filterDefaultValues}
          empty={empty}
          resource={resource}
          basePath={basePath}
          exporter={false}
          perPage={50}
          bulkActionButtons={bulkActionButtons ? bulkActionButtons : false}
          pagination={ListPagination ? <ListPagination /> : <></>}
          filters={PostFilter ? <PostFilter /> : <></>}
          className={props.listItem}
        >
          <>
            {props.mapData?.length > 0 && props.mapData?.map((item) => (
              <div className={Style.mobileCardcontainer}>
                <div className={Style.divContainer}>
                  {React.Children.map(props.children, (field) => {
                    if (field) {
                      return React.cloneElement(field, {
                        record: item,
                      })
                    }
                  })}
                </div>
              </div>
            ))}
          </>
        </List>
      )}
    </div>
  );
};
