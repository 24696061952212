import * as React from "react";
import { createElement } from "react";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { makeStyles } from "@material-ui/core/styles";
import { is_empty } from "../../../utils/validations";
import style from "../../../Style.module.css";

import cartouche from "./cartouche.png";
import cartoucheDark from "./cartoucheDark.png";
import Tooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  info: {
    margin: "-2px -1px 0 5px",
    fontSize: "16px !important",
  },
  no_bottom_border_radius: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: "none",
  },
  main: () => ({
    overflow: "inherit",
    padding: 16,
    background: `url(${
      theme.palette.type === "dark" ? cartoucheDark : cartouche
    }) no-repeat`,
    backgroundSize: "contain",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#493AB1",
    },
  }),
  title: {
    marginBottom: "5px !important",
  },
  tooltip: {
    maxWidth: "250px",
  },
}));

const CardWithIcon = (props) => {
  const {
    icon,
    title,
    subtitle,
    to,
    children,
    remove_bottom_border_radius,
    info,
  } = props;
  const classes = useStyles(props);

  return (
    <Card
      style={{ position: "relative", overflow: "visible" }}
      onClick={() => {
        if (!is_empty(to))
          window.location.href = `${window.location.origin}/#${to}`;
      }}
      className={`${classes.card} ${to ? "pointer" : ""} ${
        remove_bottom_border_radius ? classes.no_bottom_border_radius : ""
      }`}
    >
      <div className={classes.main}>
        <Box width="3em" className="icon">
          {createElement(icon, { fontSize: "large" })}
        </Box>
        <Box textAlign="right">
          <Typography
            className={`handle_tooltip ${style.flex_row} ${classes.title}`}
            color="textSecondary"
          >
            {title}
            {info && (
              <Tooltip
                text={info}
                noRightMargin
                tooltipProps={{ tooltipClassName: classes.tooltip }}
              />
            )}
          </Typography>
          <Typography variant="h5" component="h2">
            {!is_empty(subtitle) || subtitle === 0 ? subtitle : " "}
          </Typography>
        </Box>
      </div>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
