import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  offeringRoot: {
    width: "100%",
    display: "flex",
    gap: "12px",
    "& img": {
      height: "36px",
      width: "36px",
      backgroundSize: "cover",
      backgroundColor: "#D9D9D9",
      borderRadius: "5px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  offeringTitle: {
    fontSize: "14px",
    lineHeight: "17px",
  },
  offeringPrice: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  durationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  timePicker: {
    width: '100%',
  },
  datePicker: {
    width: "100%",
    marginRight: ({ is_desktop }) => is_desktop ? '35px' : '15px',
  },
  durationLabel: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme?.palette?.secondary?.main,
    lineHeight: '17px',
    marginBottom: '8px'
  },
  discountPeriodInfo: {
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.secondary?.main,
    gap: "8px",
    "& span": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "& svg": {
      fontSize: "19.21px",
    },
  },
  workshopTimePickerAnchor: {
    width: "150px"
  }
}));
