import React from "react";
import style from "./Style.module.css";

export default function BrowserNotSupported() {
  return (
    <section className={` ${style.browserNotSupportedContainer} `}>
      <div className={` ${style.infoBox} `}>
        <span>
          <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M92.7 51.5C92.7 62.427 88.3593 72.9063 80.6328 80.6328C72.9063 88.3593 62.427 92.7 51.5 92.7C40.5731 92.7 30.0937 88.3593 22.3672 80.6328C14.6408 72.9063 10.3 62.427 10.3 51.5C10.3 40.5731 14.6408 30.0937 22.3672 22.3672C30.0937 14.6408 40.5731 10.3 51.5 10.3C62.427 10.3 72.9063 14.6408 80.6328 22.3672C88.3593 30.0937 92.7 40.5731 92.7 51.5V51.5ZM56.65 72.1C56.65 73.4659 56.1075 74.7758 55.1416 75.7416C54.1758 76.7075 52.8659 77.25 51.5 77.25C50.1342 77.25 48.8243 76.7075 47.8584 75.7416C46.8926 74.7758 46.35 73.4659 46.35 72.1C46.35 70.7342 46.8926 69.4243 47.8584 68.4584C48.8243 67.4926 50.1342 66.95 51.5 66.95C52.8659 66.95 54.1758 67.4926 55.1416 68.4584C56.1075 69.4243 56.65 70.7342 56.65 72.1V72.1ZM51.5 25.75C50.1342 25.75 48.8243 26.2926 47.8584 27.2584C46.8926 28.2243 46.35 29.5342 46.35 30.9V51.5C46.35 52.8659 46.8926 54.1758 47.8584 55.1416C48.8243 56.1075 50.1342 56.65 51.5 56.65C52.8659 56.65 54.1758 56.1075 55.1416 55.1416C56.1075 54.1758 56.65 52.8659 56.65 51.5V30.9C56.65 29.5342 56.1075 28.2243 55.1416 27.2584C54.1758 26.2926 52.8659 25.75 51.5 25.75Z"
              fill="rgba(0,0,0,0.4)"
            />
          </svg>
        </span>
        <br></br>
        <h1>Browser is not supported!</h1>
        <p className={` ${style.description} `}>
          Looks like you are using an older browser, which is not supported by
          Exly. Please, use latest Google Chrome, Mozilla Firefox or Opera for
          the best user experience.
        </p>
        <p className={` ${style.description} `}>
          You can choose latest browser from below
        </p>

        <div className={`${style.browserList}`}>
          <div>
            <a href="https://www.google.com/intl/en_in/chrome/" target="_blank">
              <img
                src={require("../../../assets/images/browser-logos/chrome.png")}
              ></img>
            </a>
          </div>
          <div>
            <a
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
            >
              <img
                src={require("../../../assets/images/browser-logos/firefox.png")}
              ></img>
            </a>
          </div>
          <div>
            <a href="https://www.opera.com/download" target="_blank">
              <img
                src={require("../../../assets/images/browser-logos/opera.png")}
              ></img>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
