import React, { Component } from "react";
import { is_empty } from "../../../utils/validations";
import style from "./Style.module.css";

class Alert extends Component {
  render() {
    const {
      primary_cta_text,
      secondary_cta_text,
      header,
      body,
      primary_action,
      secondary_action,
      tertiary_cta_text,
      tertiary_action,
    } = this.props;

    return (
      <div className={style.backdrop}>
        <div className={`${style.alert} alert_modal`}>
          {!is_empty(tertiary_cta_text) ? (
            <span
              className={`${style.preview_cta}`}
              style={{ margin: 0 }}
              onClick={() => {
                tertiary_action();
              }}
            >
              ✖
            </span>
          ) : (
            <></>
          )}
          <div
            className={`${style.row} ${style.bold_black_large_text} text-center`}
          >
            {header}
          </div>
          <div className={`${style.row} ${style.alert_body} text-center`}>
            {body}
          </div>

          <div className={`${style.flex_row} ${style.alert_actions}`}>
            {secondary_cta_text ? (
              <span
                className={`${style.preview_cta}`}
                onClick={() => {
                  secondary_action && secondary_action();
                }}
              >
                {secondary_cta_text}
              </span>
            ) : null}
            {primary_cta_text ? (
              <span
                className={`${style.save_cta}`}
                onClick={() => {
                  primary_action && primary_action();
                }}
              >
                {primary_cta_text}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Alert;
