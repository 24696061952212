import React from "react";
import styles from "./Style.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import PreviewModal from "./PreviewModal";
import { is_empty } from "../../../utils/validations";
import dataProvider from "../../../data/dataProvider";
import { is_desktop } from "utils/Utils";
import constants from "constants/constants";

const EmptyList = (props) => {
  const { secondary_cta, primary_cta, preview_image, handleCta } = props;

  let identifier = primary_cta
    ? primary_cta
    : props.module === "testimonial"
    ? "Add Testimonial"
    : "Add Image/ Video";

  const logo = require(props.module === "testimonial"
    ? "../../../assets/images/testimonial_empty.png"
    : props.module === "transaction"
    ? "../../../assets/images/meditating.png"
    : props.module === "member"
    ? "../../../assets/images/teams_empty.png"
    : "../../../assets/images/gallery_empty.png");

  const [showModal, set_showModal] = React.useState(false);

  return (
    <div className={`ml-3`}>
      {is_desktop ? (
        <>
          <div>
            <img src={logo} alt={"logo"} className={styles.image} />
          </div>

          {!is_empty(props.caption) ? (
            <div className={styles.title}>{props.caption}</div>
          ) : constants.transaction_module ? (
            <div>
              <div className={styles.title}>
                {props.module_text === "Ad Lead"
                  ? "Oops, No leads captured yet"
                  : `No ${props.module_text}s yet`}
              </div>
              <div className={styles.body}>
                {props.module_text === "session" ||
                props.module_text === "refund"
                  ? ""
                  : props.module_text === "Ad Lead"
                  ? "Details of people who want to get in touch with you or are in interested in any of your offerings will show here"
                  : `As soon as we have new ${props.module_text}, it will show up here.`}
              </div>
            </div>
          ) : props.module === constants.testimonial_module ? (
            <div className={styles.emptyTestimonialText}>
              You have not added any testimonials yet. Click below to add a new
              testimonial. Ask your customers for testimonials and reviews to
              publish them on your webpage.
            </div>
          ) : props.module === constants.member_module ? (
            <div>
              You have not added any team members yet. Click below to add a
              member. Adding a {'"Team"'} section to your website is an easy,
              effective way to give your business an accessible face. As one of
              your most important sections, it gives your customers an idea of
              who exactly {"they'll"} be working with, and shows that {"you're"}
              proud of the people on your team.
            </div>
          ) : (
            <div className={styles.emptyTestimonialText}>
              You have not added any images or videos yet. Click below to add
              new media. Creating a beautiful gallery helps paint a better
              picture for visitors and increase purchases.
            </div>
          )}

          {!is_empty(primary_cta) ||
          props.module !== constants.transaction_module ? (
            <>
              <div className={`row no-gutters`}>
                <div
                  className={`${styles.save_cta} ${
                    props.module === constants.gallery_module
                      ? styles.gallery_save_cta
                      : ""
                  }`}
                  onClick={() => {
                    dataProvider.postAnalyticalEvent(
                      `${props.module_text}s`,
                      `add_${props.module_text}`,
                      {
                        flow: props.module_text,
                        funnel: props.module_text,
                        ui_component: "Text Button",
                        identifier: identifier,
                      }
                    );
                    handleCta();
                  }}
                >
                  {identifier}
                </div>
                {preview_image ? (
                  <div
                    onClick={() => {
                      set_showModal(true);
                    }}
                    className={`pointer ${styles.sample_cta} ${
                      props.module === constants.gallery_module
                        ? styles.gallery_sample_cta
                        : ""
                    }`}
                  >
                    {secondary_cta}
                  </div>
                ) : null}
              </div>
              {props.isNewUser && !props.arrowClick && props.continueGuide ? (
                <img
                  src={require("../../../assets/images/arrow.png")}
                  className={styles.arrowClick}
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <div style={{ padding: "2rem 1rem 0px" }}>
          <div className={styles.centreFlexbox}>
            <img src={logo} alt="No data available" />
          </div>
          <div style={{ marginTop: "2rem" }}>
            {!is_empty(props.caption) ? (
              <div className={styles.title}>{props.caption}</div>
            ) : props.module === "transaction" ? (
              <div>
                <div className={styles.bold_black_medium_text}>
                  {props.module_text === "Ad Lead"
                    ? "Oops, No leads captured yet"
                    : `No ${props.module_text}s yet`}
                </div>
                <div className={styles.body}>
                  {props.module_text === "session" ||
                  props.module_text === "refund"
                    ? ""
                    : props.module_text === "Ad Lead"
                    ? "Details of people who want to get in touch with you or are in interested in any of your offerings will show here"
                    : `As soon as you create a new ${props.module_text}, it will show up here.`}
                </div>
              </div>
            ) : props.module === constants.testimonial_module ? (
              <div className={styles.emptyTestimonialText}>
                You have not added any testimonials yet. Click below to add a
                new testimonial. Ask your customers for testimonials and reviews
                to publish them on your webpage.
              </div>
            ) : props.module === constants.member_module ? (
              <div>
                You have not added any team members yet. Click below to add a
                member. Adding a {'"Team"'} section to your website is an easy,
                effective way to give your business an accessible face. As one
                of your most important sections, it gives your customers an idea
                of who exactly {"they'll"} be working with, and shows that
                {"you're"} proud of the people on your team.
              </div>
            ) : (
              <div className={styles.emptyTestimonialText}>
                You have not added any images or videos yet. Click below to add
                new media. Creating a beautiful gallery helps paint a better
                picture for visitors and increase purchases.
              </div>
            )}
          </div>
          <div className={styles.bottomDiv}>
            <div className={styles.flex_box}>
              <button
                className={styles.noborder}
                style={{ float: "left" }}
                onClick={() => set_showModal(true)}
              >
                {secondary_cta}
              </button>
              <div
                onClick={() => {
                  dataProvider.postAnalyticalEvent(
                    `${props.module_text}s`,
                    `add_${props.module_text}`,
                    {
                      flow: props.module_text,
                      funnel: props.module_text,
                      ui_component: "Text Button",
                      identifier: identifier,
                    }
                  );
                  handleCta();
                }}
                style={{ float: "right" }}
                className={`${styles.save_cta}`}
              >
                {primary_cta}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={() => {
          set_showModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <PreviewModal
          close={() => {
            set_showModal(false);
          }}
          preview_image={preview_image}
        />
      </Modal>
    </div>
  );
};

export default EmptyList;
