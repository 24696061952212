import { useState, useEffect, useMemo } from "react";
import { dataProvider } from "data";
import moment from "moment";
import {
  isInternationalTimezone,
  setNextStepsInLocalStorage,
} from "utils/AuthUtil";
import api from "data/APIs";
import { DEFAULT_TIME_FORMAT_12 } from "constants/dateTime";
import { useOfferings } from "hooks/useOfferings";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { getWhatsNextInfo } from "features/Onboarding/modules/WhatsNext/utils/whatsNext.utils";

const useLimitedTimeOffer = () => {
  const [initialValues, setInitialValues] = useState({
    listing: "",
    offerName: "Flash Deal",
    INR: true,
    INRPrice: null,
    USD: false,
    USDPrice: null,
    start_date: moment(new Date()).format("YYYY-MM-DD"),
    end_date: moment(new Date()).add("days", 7).format("YYYY-MM-DD"),
    start_date_international: moment(new Date()).format("YYYY-MM-DD"),
    end_date_international: moment(new Date())
      .add("days", 7)
      .format("YYYY-MM-DD"),
    start_time: moment().add(1, "minutes").format(DEFAULT_TIME_FORMAT_12),
    end_time: "11:59 pm",
    start_time_international: "09:00 am",
    end_time_international: "11:00 pm",
    region: isInternationalTimezone() ? 1 : 0,
  });
  const [listings, setListings] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [firstLimitedOffer, setFirstLimitedOffer] = useState(false);
  const [limitedOfferCreative, setLimitedOfferCreative] = useState(false);
  const [listingUuid, setListingUuid] = useState("");
  const [offers, setOffers] = useState(0);
  const [selectedListing, setSelectedListing] = useState([]);

  const recordData = window.sessionStorage.getItem("editData")
    ? JSON.parse(window.sessionStorage.getItem("editData"))
    : null;

  const { fetchOfferingsList } = useOfferings();

  useEffect(() => {
    (async () => {
      const data = await fetchOfferingsList({
        is_offering_variants_enabled: false,
        page: "all",
        status: offering_availability_statuses.live,
      });
      if (isRequestSuccessful(data.status)) {
        setListings(data.data.listings);
      }
    })();

    (async () => {
      const offerData = await dataProvider.custom_request(
        "host/offer/list",
        "GET"
      );
      if (offerData.status === 200) {
        setOffers(offerData.data.offers.length);
      }
    })();

    if (window.sessionStorage.getItem("editData")) {
      setIsEdit(true);
      const recordData = window.sessionStorage.getItem("editData")
        ? JSON.parse(window.sessionStorage.getItem("editData"))
        : null;
      const start_dateTime = DateCoversion(recordData.start_datetime);
      const end_dateTime = DateCoversion(recordData.end_datetime);
      setInitialValues({
        ...initialValues,
        INRPrice: recordData.price,
        listing: {
          ...recordData,
          currency: recordData.creator_currency,
          type: recordData.listing_type,
          price_per_head: recordData.listing_price,
          cover_image: recordData.listing_cover,
          title: recordData.listing_name,
          label: recordData.listing_name,
        },
        offerName: recordData.title,
        start_date: start_dateTime.date,
        start_time: start_dateTime.time,
        end_date: end_dateTime.date,
        end_time: end_dateTime.time,
        region: recordData.region,
      });
    }
  }, []);

  const DateField = (dateConvert, onlyTime, payloadDate) => {
    const replaceUTC = onlyTime
      ? dateConvert
      : dateConvert?.replace("T", " ")?.replace("Z", "");
    let month;
    let day;
    let year;
    let time;
    if (!onlyTime) {
      const dateFormat = replaceUTC?.split(" ");
      const timeFormat = dateFormat && dateFormat[1]?.split(":");
      const date = new Date(dateFormat[0]);
      month = date.toLocaleString("default", { month: "short" });
      day = date.getDate();
      year = `${date.getFullYear()}`;

      let hours = timeFormat[0]; // gives the value in 24 hours format
      const AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = timeFormat[1];
      time =
        (hours < 10 ? `0${hours}` : hours) +
        ":" +
        (minutes < 10 ? `${minutes}` : minutes) +
        " " +
        AmOrPm;
    } else {
      const date = new Date(replaceUTC);
      month = payloadDate
        ? `${date.getMonth() < 9 ? 0 : ""}${date.getMonth() + 1}`
        : date.getMonth();
      day = date.getDate();
      year = date.getFullYear();
      let hours = date.getHours(); // gives the value in 24 hours format
      const AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = date.getMinutes();
      time =
        (hours < 10 ? `0${hours}` : hours) +
        ":" +
        (minutes < 10 ? `0${minutes}` : minutes) +
        " " +
        AmOrPm;
    }

    let date1 = `${year}-${month}-${day}`;
    return {
      date1,
      time,
    };
  };

  const DateCoversion = (entereddate) => {
    const replaceUTC = entereddate?.replace("T", " ")?.replace("Z", "");
    const dateFormat = replaceUTC?.split(" ");
    const timeFormat = dateFormat && dateFormat[1]?.split(":");
    const date = new Date(dateFormat[0]);
    let hours = timeFormat[0]; // gives the value in 24 hours format
    const AmOrPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    let minutes = timeFormat[1];
    let time =
      (hours < 10 ? `0${hours}` : hours) +
      ":" +
      (minutes < 10 ? `${minutes}` : minutes) +
      " " +
      AmOrPm;

    return {
      time,
      date,
    };
  };

  const compareDates = (date, todaysDate) => {
    if (typeof todaysDate === "string" && todaysDate?.includes("-"))
      todaysDate = new Date(todaysDate);
    if (
      date?.getDate() === todaysDate?.getDate() &&
      date?.getFullYear() === todaysDate?.getFullYear() &&
      date?.getMonth() === todaysDate?.getMonth()
    ) {
      return true;
    }

    return false;
  };

  const handleCreateOffer = async (formState) => {
    let payload = {
      title: formState.offerName,
      listing_uid: formState.listing.uuid,
      price_inr:
        formState.INRPrice && parseFloat(formState.INRPrice).toFixed(2),
      start_date: formState.start_date,
      start_time: formState.start_time,
      end_date: formState.end_date,
      end_time: formState.end_time,
    };

    if (formState.USD) {
      payload = {
        ...payload,
        price_international:
          formState.USDPrice && parseFloat(formState.USDPrice).toFixed(2),
        start_date_international: formState.start_date_international,
        start_time_international: formState.start_time_international,
        end_date_international: formState.end_date_international,
        end_time_international: formState.end_time_international,
      };
    }

    if (window.sessionStorage.getItem("editData")) {
      const recordData = window.sessionStorage.getItem("editData")
        ? JSON.parse(window.sessionStorage.getItem("editData"))
        : null;
      const payload = {
        offer_uid: recordData.uuid,
        updated_values: {},
      };
      if (recordData.title !== formState.offerName) {
        payload.updated_values.title = formState.offerName;
      }
      if (recordData.price !== formState.INRPrice) {
        payload.updated_values.price =
          formState.INRPrice && parseFloat(formState.INRPrice).toFixed(2);
      }
      if (
        !compareDates(
          DateCoversion(recordData.end_datetime).date,
          formState.end_date
        ) ||
        DateCoversion(recordData.end_datetime).time !== formState.end_time
      ) {
        payload.updated_values.end_date =
          DateField(recordData.end_datetime).time !== formState.end_time
            ? DateField(formState.end_date, true, true).date1
            : formState.end_date;
        payload.updated_values.end_time = formState.end_time;
      }
      if (
        !compareDates(
          DateCoversion(recordData.start_datetime).date,
          formState.start_date
        ) ||
        DateCoversion(recordData.start_datetime).time !== formState.start_time
      ) {
        payload.updated_values.start_date =
          DateField(recordData.start_datetime).time !== formState.start_time
            ? DateField(formState.start_date, true, true).date1
            : formState.start_date;
        payload.updated_values.start_time = formState.start_time;
      }
      try {
        const data = await dataProvider.custom_request(
          "host/offer/update",
          "POST",
          payload
        );
        if (data.status === 200) {
          window.sessionStorage.removeItem("editData");
          alert("Offer updated successfully!");
          window.location.reload();
        }
      } catch (err) {
        alert(err?.body?.message);
      }
    } else {
      try {
        const data = await dataProvider.custom_request(
          "host/offer/create",
          "POST",
          payload
        );
        if (data.status === 200) {
          const offerData = await dataProvider.custom_request(
            api.get_offers,
            "GET"
          );
          const currentListing = offerData.data.offers.filter(
            (listing) =>
              listing.listing_uuid === payload.listing_uid &&
              listing.status != 2
          );
          setListingUuid(payload.listing_uid);
          setLimitedOfferCreative(true);
          setSelectedListing(currentListing[0]);
          const nextSteps = getWhatsNextInfo();
          if (nextSteps) {
            nextSteps.has_discount_code = true;
            setNextStepsInLocalStorage({ data: nextSteps });
          }
          if (offers === 0) {
            setFirstLimitedOffer(true);
            return firstLimitedOffer;
          }

          const {
            data: { domestic_offer, international_offer },
          } = data;

          // return { listingOfferData: { listing offer data response }, limitedOfferCreative: boolean }
          return {
            listingOfferData: {
              region: initialValues.region,
              listing_uuid: payload.listing_uid,
              ...(payload.price_international
                ? international_offer
                : domestic_offer),
            },
            limitedOfferCreative,
          };
        }
      } catch (err) {
        alert(err?.body?.message);
      }
    }
  };

  const offeringOptions = useMemo(() => {
    const currListing = initialValues.listing;
    if (isEdit && currListing) {
      return [
        {
          ...currListing,
          value: currListing,
        },
      ];
    }
    if (!listings.length) return [];

    return listings.map((item) => ({
      ...item,
      label: item.title,
      value: item,
    }));
  }, [initialValues.listing, isEdit, listings]);

  return {
    offeringOptions,
    recordData,
    handleCreateOffer,
    isEdit,
    initialValues,
    DateField,
    firstLimitedOffer,
    limitedOfferCreative,
    listingUuid,
    selectedListing,
  };
};

export default useLimitedTimeOffer;
