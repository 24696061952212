import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  salescheckbox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  checkboxtitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme?.palette?.secondary?.main,
    margin: 0,
  },
  offerfeildContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ({ is_desktop }) => (is_desktop ? "20px" : "18px"),
  },
  gstInfo: {
    display: 'flex',
    gap: '8px',
    fontSize: '12px',
    padding: '10px 12px',
    borderRadius: '5px',
    color: theme?.palette?.secondary?.main,
    background: theme?.palette?.primary?.shade_50,
    lineHeight: '14px',
    "& svg": {
      marginTop: '-2px',
      fontSize: '19.21px',
      color: theme?.palette?.primary?.main
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  createOfferBtn: {
    color: theme?.palette?.primary?.main,
    marginLeft: '4px',
    cursor: 'pointer'
  },
  inputLabel: {
    fontWeight: 500,
  },
}));
