import * as React from "react";
import FeedbackIcon from "@material-ui/icons/HelpOutline";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { is_empty, containsOnlySpaces } from "../../../utils/validations";
import dataProvider from "../../../data/dataProvider";
import api from "../../../data/APIs";

import {
  Tooltip,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";
import { is_desktop } from "utils/Utils";
import {
  notification_color_map,
  notification_variant_map,
  useNotifications,
} from "utils/hooks";
import styles from "./feedback.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Input } from "@my-scoot/component-library-legacy";
import { apiMethods } from "data/api.constants";
import { EXLY_SUPPORT_CONSTANTS } from "constants/exlySupport.constants";

const Feedback = ({ width, height, color }) => {
  const { pushNotification } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const [show_popup, set_show_popup] = React.useState(false);
  const [feedback, set_feedback] = React.useState("");
  const handleSendClick = async () => {
    try {
      if (!is_empty(feedback) && !containsOnlySpaces(feedback)) {
        const status = await dataProvider.custom_request(
          api.add_suggestion,
          apiMethods.POST,
          { suggestion: feedback }
        );
        pushNotification(status.message, {
          variant: notification_variant_map.outlined,
          color: notification_color_map.info,
        });
        set_feedback("");
        set_show_popup(false);
      }
    } catch (err) {
      pushNotification(err.body.message, {
        variant: notification_variant_map.outlined,
        color: notification_color_map.error,
      });
    }
  };

  const handleOpenSupportUrl = () => {
    window.open(EXLY_SUPPORT_CONSTANTS.exly_support_url, "_blank");
  };

  return (
    <div>
      <div
        className={`${
          isDesktop ? styles.feedback_color : styles.feedback_color_mobile
        } pointer dm_font`}
        onClick={handleOpenSupportUrl}
      >
        <ThemeProvider theme={creatorToolTheme}>
          <Tooltip arrow color="primary" title={is_desktop ? "Solutions" : ""}>
            <FeedbackIcon style={{ width, height, color }} />
          </Tooltip>
        </ThemeProvider>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show_popup}
        onClose={() => {
          set_show_popup(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${styles.modal} ${styles.flex_row} alert_modal dm_font`}
          style={{
            left: isDesktop ? "72%" : "50%",
            width: isDesktop ? "40%" : "90%",
          }}
        >
          <Input
            autoFocus
            type="text"
            value={feedback}
            className={styles.feedback_input}
            placeholder="Feedback on this page?"
            onChange={(e) => {
              set_feedback(e.target.value);
            }}
          />

          <span
            className={`${styles.save_cta} pointer`}
            onClick={handleSendClick}
          >
            Send
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default Feedback;
