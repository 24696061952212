import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";
import FacebookIcon from "../../../assets/vectors/social/facebook.svg";
import TwitterIcon from "../../../assets/vectors/social/twitter.svg";
import WhatsAppIcon from "../../../assets/vectors/social/whatsapp.svg";
import InstagramIcon from "../../../assets/vectors/social/instagram.svg";
import EmailRounded from "../../../assets/vectors/social/emailRounded.svg";
import constants from "../../../constants/constants";

import style from "./Style.module.css";
import { CloudDownload } from "@material-ui/icons";
import { toBlob, toJpeg } from "html-to-image";
import { is_empty } from "utils/validations";
import { Creative } from "./Creative";
import { is_desktop } from "utils/Utils";
import { useNotifications } from "utils/hooks";
import { Button } from "@my-scoot/component-library-legacy";
import {
  appendUrlParams,
  getListingShareUrl,
  navigateTo,
} from "utils/urlUtils";
import { fetchListingDetails } from "utils/listing/fetchListingDetails";
import { fetchSEODataForPlanListing } from "schedule-v2/Plans/utils";
import { app_route_keys } from "constants/urlPaths";
import { EXLY_CONNECT_TABS } from "features/ExlyConnect/ExlyConnect.constants";
import { schedule_types_ids } from "constants/schedule";
import { getCheckoutPageShareUrl } from "features/CheckoutPages/CheckoutPages.utils";
import { logError } from "utils/error";
import classnames from "classnames";
import {
  CHECKOUT_PAGE_ROUTE_KEYS,
  CHECKOUT_PAGES_ROUTE_GENERATORS,
} from "features/CheckoutPages/constants/CheckoutPages.constants";
import { checkIsCheckoutEnabledListing } from "features/CheckoutPages/utils/checkIsCheckoutEnabledListing";

function EnhancedShare(props) {
  const { pushNotification } = useNotifications();
  const {
    title,
    listing = {},
    listingUuid,
    setShowModal,
    showSuccess = false,
    showBroadcast = false,
    reloadOnClose = true,
    hideCheckoutUrl = false,
    copyListingPageCTAText = "Copy Offering Page Link",
    shareLink: customShareLink,
    wrapperClassName,
    showPaymentPageButton = false,
    dynamicLinkUuid = "",
  } = props;

  const [share_link, set_share_link] = useState(null);
  const [shareCheckoutLink, setShareCheckoutLink] = useState("");

  const history = useHistory();

  const listingUUID = listing.parent_listing_uuid
    ? listing.parent_listing_uuid
    : listing.uuid;

  const isListingType = !listing.parent_listing_uuid;

  const handlePaymentPageButtonClick = () => {
    if (showPaymentPageButton && dynamicLinkUuid) {
      history.push(
        `${CHECKOUT_PAGES_ROUTE_GENERATORS[
          CHECKOUT_PAGE_ROUTE_KEYS.checkout_page_update_details
        ]({
          listingUuid: listingUUID,
          dynamicLinkUuid,
        })}`
      );
    }
  };

  const handleClickOutside = (e) => {
    if (e?.target?.id === "modalWrapper") {
      setShowModal(false);
      if (reloadOnClose) window.location.reload();
    }
  };

  const downloadBanner = () => {
    toJpeg(document.getElementById("listing_creative"), {
      cacheBust: true,
    }).then(function (dataUrl) {
      const link = document.createElement("a");
      link.download = "id.jpeg";
      link.href = dataUrl;
      link.click();
    });
  };

  const handleBroadcast = async () => {
    if (!showBroadcast) return;

    try {
      window.location.href = `${window.location.origin}/#/ComposeEmail?template=${constants.exly_inv_template}&lisitng_uuid=${listingUUID}`;
    } catch (err) {
      pushNotification(err.message, {
        variant: "outlined",
        color: "coral_red",
      });
    }
  };

  // Since the type for listings contained in a plan are stored in the plan_type key which is
  // needed to get listing specific text, a common property is declared here for that purpose.
  //
  // ?? is used instead of || as || will fall to the second property for the case of listing.type === 0 [Appointments],
  // whereas ?? will do that only for listing.type === undefined
  // const listing_type = listing.type ?? listing.plan_type;
  //
  // This useEffect, sets the offering url and checkout url, based on the listing data coming from
  // the props (from paginated listing). We also call the specific listing details api, to update the
  // checkout and the offering urls after this.
  useEffect(() => {
    set_share_link(getListingShareUrl(listing));
    if (checkIsCheckoutEnabledListing(listing)) {
      setShareCheckoutLink(getCheckoutPageShareUrl(listing));
    }
  }, [listing]);

  useEffect(() => {
    if (!isListingType || !listingUUID) return;
    fetchListingDetails({ listingUuid: listingUUID })
      .then((apiResponse) => {
        const listingData = apiResponse?.data?.event || {};
        set_share_link(getListingShareUrl(listingData));

        if (checkIsCheckoutEnabledListing(listingData)) {
          setShareCheckoutLink(getCheckoutPageShareUrl(listingData));
        }
      })
      .catch((err) => {
        logError({
          error: err,
          when: "Error fetching listing detail",
          occuredAt: "src/ui/modules/EnhancedShare/index.js",
        });
      });
  }, [isListingType, listingUUID]);

  useEffect(() => {
    callFetchSEODataForPlanListing();
  }, [isListingType]);

  const callFetchSEODataForPlanListing = async () => {
    if (!isListingType) {
      const response = await fetchSEODataForPlanListing(listing?.uuid);
      if (response.status === 200) {
        if (response?.data?.seo_preview)
          set_share_link(
            getListingShareUrl({
              ...listing,
              plan_slug: response?.data?.seo_preview?.url_slug,
            })
          );

        if (checkIsCheckoutEnabledListing(listing)) {
          setShareCheckoutLink(
            getCheckoutPageShareUrl({
              ...listing,
              url_slug: response?.data?.seo_preview?.url_slug,
            })
          );
        }
      } else {
        logError({
          error: response?.error,
          when: "Error while fetching Data for Plan SEO",
          occuredAt: "src/ui/modules/EnhancedShare/index.js",
        });
      }
    }
  };

  const onShareClick = async () => {
    if (navigator?.share) {
      pushNotification("Preparing your creative.. please wait", {
        variant: "outlined",
        color: "primary",
      });
      toBlob(document.getElementById("listing_creative"), {
        cacheBust: true,
      }).then((blob) => {
        navigator
          .share({
            title: `${listing.title}`,
            text: `Connect with me on ${customShareLink ?? share_link}`,
            files: [
              new File([blob], "sharingBanner.png", {
                type: blob.type,
                lastModified: new Date().getTime(),
              }),
            ],
          })
          .catch((error) => {
            pushNotification(
              "An error occured. Please try again after some time.",
              { variant: "outlined", color: "coral_red" }
            );
            console.error(error);
          });
      });
    } else {
      pushNotification("System does not support sharing files", {
        variant: "outlined",
        color: "coral_red",
      });
    }
  };

  return (
    <div
      className={classnames(style.share_modal_wrapper, wrapperClassName)}
      onClick={handleClickOutside}
      id="modalWrapper"
    >
      <div className={`fit_content ${style.share_modal}`}>
        <div
          className={style.share_modal_close}
          title="close"
          onClick={() => {
            setShowModal(false);
            if (reloadOnClose) window.location.reload();
          }}
        >
          &times;
        </div>
        <div className={`text-center ${showSuccess ? "" : style.title}`}>
          {title}
        </div>

        <div className={`d-flex justify-content-center`}>
          {!is_empty(listing?.cover_image) || !is_empty(listingUuid) ? (
            <Creative listingUuid={listingUuid} listing={listing} />
          ) : null}
        </div>

        <div className={style.modal_event_link}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={customShareLink ?? share_link}
          >
            {customShareLink ?? share_link}
          </a>
        </div>
        <div
          className={style.share_modal_button}
          onClick={() => {
            if (navigator && navigator.clipboard) {
              navigator.clipboard.writeText(customShareLink ?? share_link);
              pushNotification("Link Copied!", {
                variant: "outlined",
                color: "fine_pine",
              });
            }
          }}
        >
          <FileCopyOutlined className={style.share_modal_button_icon} />
          {copyListingPageCTAText}
        </div>

        {showPaymentPageButton && checkIsCheckoutEnabledListing(listing) && (
          <div
            className={style.share_modal_button}
            onClick={handlePaymentPageButtonClick}
          >
            <CreateOutlinedIcon className={style.share_modal_button_icon} />
            Edit Payment Page
          </div>
        )}

        {/* Only render this in case */}
        {!hideCheckoutUrl && shareCheckoutLink && (
          <div
            className={style.share_modal_button}
            onClick={() => {
              if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(shareCheckoutLink);
                pushNotification("Link Copied!", {
                  variant: "outlined",
                  color: "fine_pine",
                });
              }
            }}
          >
            <ShoppingCartOutlinedIcon
              className={style.share_modal_button_icon}
            />
            Copy Payment Page Link
          </div>
        )}

        <div
          className={`${style.share_modal_button} d-none d-md-block`}
          onClick={downloadBanner}
        >
          <CloudDownload
            className={`${style.share_modal_button_icon} ${style.download_svg}`}
          />{" "}
          Download this banner
        </div>

        <div
          className={`${style.share_modal_button} ${style.share_modal_button_noLink}`}
        >
          <div className="pb-2 border-bottom">
            Share this as post on Social Media
          </div>
          <div className="pt-2 d-flex justify-content-around">
            <img
              className={`${style.share_social_icon} pointer`}
              src={WhatsAppIcon}
              onClick={() => {
                is_desktop
                  ? window.open(
                      `https://api.whatsapp.com/send?&text=${`Connect with me on ${
                        customShareLink ?? share_link
                      }`}`
                    )
                  : onShareClick();
              }}
            />
            <img
              className={`${style.share_social_icon} pointer`}
              src={InstagramIcon}
              onClick={() => {
                if (is_desktop) {
                  pushNotification("Creative downloaded! You can share now.", {
                    variant: "outlined",
                    color: "fine_pine",
                  });
                  downloadBanner();
                } else {
                  onShareClick();
                }
              }}
            />
            <img
              className={`${style.share_social_icon} pointer`}
              src={FacebookIcon}
              onClick={() => {
                is_desktop
                  ? window.open(
                      appendUrlParams(constants.facebook_share_url, {
                        link: customShareLink ?? share_link,
                        quote: `Connect with me on ${
                          customShareLink ?? share_link
                        }`,
                      })
                    )
                  : onShareClick();
              }}
            />
            <img
              className={`${style.share_social_icon} pointer`}
              src={TwitterIcon}
              onClick={() => {
                is_desktop
                  ? window.open(
                      `https://twitter.com/intent/tweet?text=${`Connect with me on ${
                        customShareLink ?? share_link
                      }`}`
                    )
                  : onShareClick();
              }}
            />
            <img
              className={`${style.share_social_icon} pointer`}
              src={EmailRounded}
              onClick={() => {
                is_desktop ? handleBroadcast() : onShareClick();
              }}
            />
          </div>
        </div>

        {listing?.address_type ===
        constants.address_type_enum.exly_connect_by_zoom ? (
          <div className={style.share_modal_exly_connect}>
            <div className="mb-2">
              Exly Connect meeting links can be accessed from its section under
              Workflow Tools
            </div>
            <Button
              onClick={() => {
                navigateTo(app_route_keys.exly_connect, {
                  args:
                    listing.type === schedule_types_ids.no_schedule
                      ? EXLY_CONNECT_TABS.on_demand.value
                      : EXLY_CONNECT_TABS.scheduled.value,
                });
                // if exly_connect component is already mounted once , then on opening again default filter are not applying
                window.location.reload();
              }}
            >
              Go to Exly Connect
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default EnhancedShare;
