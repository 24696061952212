import module_style from "./Style.module.css";
import React from "react";
import { is_empty } from "utils/validations";
import { getAuthData } from "utils/AuthUtil";
import { ReactComponent as CalendarIcon } from "assets/images/listing-sharing/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/images/listing-sharing/clock.svg";
import { ReactComponent as ArrowIcon } from "assets/images/listing-sharing/green/arrows2.svg";
import moment from "moment";
import constants from "constants/constants";
import { is_class } from "utils/Utils";
import { fetchListingDetails } from "utils/listing/fetchListingDetails";
import ExlyLoader from "../ExlyLoader";

const themes = [
  {
    slug: "purple",
    background: require("assets/images/ListingSharing/purple.png"),
    color: "rgba(137, 5, 188, 1)",
  },
  {
    slug: "green",
    background: require("assets/images/ListingSharing/green.png"),
    color: "rgba(0, 196, 105, 0.6)",
  },
  {
    slug: "blue",
    background: require("assets/images/ListingSharing/blue.png"),
    color: "rgba(211, 84, 202, 1)",
  },
];

export const Creative = ({ listing, website_share, listingUuid }) => {
  const fallbackImage = constants.default_schedule_image; //if by chance user arrives we have a fallback image

  const [theme] = React.useState(
    themes[Math.floor(Math.random() * themes.length)]
  );
  const [offering, setOffering] = React.useState(listing);
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!is_empty(listingUuid)) {
        try {
          if (processing) return;
          setProcessing(true);
          const apiResponse = await fetchListingDetails({ listingUuid });
          const listingData = apiResponse?.data?.event || {};
          if (!is_empty(listingData)) {
            setOffering(listingData);
          }
        } catch (err) {
          console.log("fetchListingDetails err", err);
        } finally {
          setProcessing(false);
        }
      }
    })();
  }, []);

  const renderCalendar = () => {
    if (
      offering?.type !== constants?.scheduleTypesId?.workshop &&
      !is_class(offering)
    )
      return null;

    if (is_class(offering))
      return (
        <div>
          Starting&nbsp;From,
          <br />
          {moment(offering?.start_date ?? offering?.start_datetime).format(
            constants.displayDateYearFormat
          )}
        </div>
      );

    if (
      offering.type === constants?.scheduleTypesId?.workshop &&
      !is_empty(offering?.request_groups)
    ) {
      let days = offering?.slots?.length;
      if (days > 1) {
        return (
          <div>
            {days}&nbsp;days&nbsp;Workshop,
            <br />
            Starting&nbsp;From,
            <br />
            {moment(offering?.request_groups[0][0]?.interval).format(
              constants.displayDateYearFormat
            )}
          </div>
        );
      } else {
        return (
          <div>
            {moment(offering?.request_groups[0][0]?.interval).format(
              constants.displayDateYearFormat
            )}
          </div>
        );
      }
    }
  };

  const renderClock = () => {
    if (is_empty(offering?.duration) || is_class(offering)) return null;
    if (
      offering?.type === constants?.scheduleTypesId?.workshop &&
      offering?.slots?.length > 1
    )
      return null;
    return offering?.duration;
  };

  //we are putting a cover image if there is no cover image
  return (
    <div
      style={
        website_share
          ? { border: "1px solid transparent", padding: 0, minHeight: "unset" }
          : {}
      }
      className={`${module_style.creative_conatiner} dm_font`}
    >
      {processing ? (
        <ExlyLoader />
      ) : (
        <div id="listing_creative">
          <img
            alt="creative"
            className={`${module_style.creative_image}`}
            src={theme.background}
          />

          {
            <div className={`${module_style.listing_data_container}`}>
              <div
                style={{
                  border: `2px solid ${theme.color}`,
                  width: "80%",
                  borderRadius: 5,
                }}
              >
                <img
                  id="creative_cover_image"
                  src={`${
                    offering?.cover_image
                      ? offering?.cover_image
                      : fallbackImage
                  }`}
                  className={`${module_style.cover_image}`}
                  alt="cover_image"
                />
              </div>

              <div
                className={`${module_style.listing_title} ${module_style.max_text} line_max_1`}
              >
                {offering?.title}
              </div>
              <div
                className={`${module_style.creator_name} ${module_style.max_text} line_max_1`}
              >
                By {getAuthData()?.display_name}
              </div>

              {theme.slug == "green" && <ArrowIcon />}

              <div
                className={`fit_content mt-2 pb-1`}
                style={{ backgroundColor: theme.color }}
              >
                <div className={`${module_style.creative_cta} fit_content`}>
                  {[
                    constants?.scheduleTypesId?.merchandise,
                    constants?.scheduleTypesId?.content,
                    constants?.scheduleTypesId?.no_schedule,
                  ].includes(offering?.type)
                    ? "Buy"
                    : constants.scheduleTypesId.telegram
                    ? "Join"
                    : "Book"}
                  &nbsp;Now
                </div>

                {!is_empty(renderCalendar()) && (
                  <div className={`d-flex ml-2 mr-2 mt-1`}>
                    <CalendarIcon />
                    <div className={`${module_style.schedule_text} ml-1`}>
                      {renderCalendar()}
                    </div>
                  </div>
                )}

                {!is_empty(renderClock()) && (
                  <div className={`d-flex ml-2 mr-2 mt-1`}>
                    <ClockIcon />
                    <div className={`${module_style.schedule_text} ml-1`}>
                      {renderClock()}&nbsp;Minutes&nbsp;
                      {offering?.type ===
                        constants?.scheduleTypesId?.one_on_one_appointment &&
                        "Session"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
};
