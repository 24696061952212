import { ExlyDatePicker, ExlyTimePicker } from "common/form";
import constants from "constants/constants";
import moment from "moment";
import React from "react";
import { Field } from "react-final-form";
import { getUserCurrencySymbol, isInternationalTimezone } from "utils/AuthUtil";
import { useStyles } from "./CustomFields.styles";
import { Tooltip } from "@my-scoot/component-library-legacy";
import { InfoRounded as InfoIcon } from "@material-ui/icons";
import { is_desktop } from "utils/Utils";

const getCurrencySymbol = (check) => {
  return getUserCurrencySymbol(
    !isInternationalTimezone() && check
      ? constants.inr_currency
      : constants.usd_currency
  );
};

export const OfferingOptionField = ({ option, initialValues, isEdit }) => {
  const classes = useStyles();

  return (
    <div className={classes.offeringRoot}>
      <img src={option.cover_image} />
      <div className={classes.contentContainer}>
        <div className={classes.offeringTitle}>
          {initialValues?.listing && isEdit
            ? initialValues.listing.listing_name
            : option.title}
        </div>
        <div className={classes.offeringPrice}>
          {initialValues?.listing && isEdit ? (
            <div>
              {`${getCurrencySymbol(
                initialValues.listing.region === constants.domestic_region
              )} ${initialValues.listing.listing_price}`}
            </div>
          ) : (
            <div>
              {`${getCurrencySymbol(option.price_per_head > 0)} ${
                option.price_per_head > 0
                  ? option.price_per_head
                  : option.price_international
              }`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const DurationPickerField = ({
  label,
  datePickerProps,
  timePickerProps,
}) => {
  const classes = useStyles({ is_desktop });

  return (
    <div>
      <div className={classes.durationLabel}>{label}</div>
      <div className={classes.durationContainer}>
        <div className={classes.datePicker}>
          <Field
            component={ExlyDatePicker}
            fullWidth
            placeholder="dd/mm/yyyy"
            minDate={new Date()}
            format={(value) => (value ? moment(value).toDate() : null)}
            {...datePickerProps}
          />
        </div>
        <Field
          component={ExlyTimePicker}
          size="small"
          desktop_width="100%"
          native_width="100%"
          popperWidth="256px"
          {...timePickerProps}
        />
      </div>
    </div>
  );
};

export const TooltipTitle = ({ title, description }) => {
  const classes = useStyles();

  return (
    <div className={classes.discountPeriodInfo}>
      <span>{title}</span>
      <Tooltip arrow color="primary" title={description} placement={is_desktop?"right":"top-end"}>
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
